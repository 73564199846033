import {
  OgImage,
  Hero,
  BA,
  DE,
  PM,
  QA,
  SE,
  UI,
  Define,
  Finalize,
  Introductory,
  Onboarding,
  Select,
  Start,
  Core,
  Flexibility,
  Overhead,
  Rapid,
  Specialized,
  SpeedingUp,
} from "../../images/Services/DedicatedTeam"

const dataForDedicatedTeam = {
  ogImage: OgImage,
  service: "dedicatedteam",
  hero: {
    title: "Hire Dedicated Software Development Team",
    subtitle: "Let’s discuss your vision and assemble the ideal team for you.",
    description: "",
    img: Hero,
  },
  recognizing: {
    title: "Recognizing the Need for  Software Development Team",
    cases: [
      {
        title: "Rapid Scaling of the Project",
        description: "With a dedicated team, you will be able to ramp up resources quickly on a project that needs rapid expansion but, at the same time, won't be compromising on quality. The model allows flexibility for tight deadlines and increased workloads while never setting your project behind.",
        img: Rapid,
      },
      {
        title: "Specialized Expertise",
        description: "With our dedicated development team for hire, you get direct access to experts in certain technologies or niche domains. Be it AI, IoT, or even mobile app development, our specialized team will carry the exact armamentarium that will help to tackle advanced requirements and effectively innovate in those particular fields.",
        img: Specialized,
      },
      {
        title: "Overhead Costs Reduction",
        description: "Salaries, training, and infrastructure make managing in-house development quite expensive. Our dedicated software development company saves you money by giving you the ability to scale resources on demand, focusing only on the active stages of development and decreasing financial commitments.",
        img: Overhead,
      },
      {
        title: "More Focus on Core Business Activities",
        description: "A dedicated development team for hire allows internal staff to focus on core business tasks and leaves development to professionals. It segments one's operations and frees up time for your business to run smoothly while new software solutions are being developed.",
        img: Core,
      },
      {
        title: "Speeding Up Time to Market",
        description: "If speed is critical, our dedicated software developers speed up development by providing a preassembled group ready to get down to business straight away. Because they have been working on similar projects, they know how to do it efficiently and thus get your product on the market faster. This gives you an edge over the competition.",
        img: SpeedingUp,
      },
      {
        title: "Flexibility in Project Management",
        description: "A dedicated development team for hire adapts to your project needs and workflow, offering flexibility in management and scaling. You can oversee the process as closely as you want or delegate and make adjustments throughout the project without impacting performance or deadlines.",
        img: Flexibility,
      },
    ]
  },
  dedicated: {
    title: 'Our dedicated software development team structure',
    description: 'If you want to hire a dedicated development team at OS-system, you get the developers that will work exclusively on your project. We will use a plain pricing model (fixed flat monthly rate) to help you forecast your expenses. This approach is a perfect model for any business seeking to efficiently manage its budget. This approach is ideal for companies that have an ongoing need for development resources.',
    cases: [
      {
        title: "Project Manager",
        description: "Our Project Managers ensure smooth collaboration by managing the timeline, budget, and communication between your team and ours. They oversee all stages of the project to keep development on track and make sure your vision is realized effectively and on schedule.",
        img: PM,
      },
      {
        title: "Software Engineers",
        description: "Core development is done by our software engineers specializing in front-end, back-end, and full-stack development. They develop your software, focusing on performance, scalability, and reliability.",
        img: SE,
      },
      {
        title: "DevOps Engineers",
        description: "DevOps Engineers are supposed to bridge development and operations. They provide seamless deployment and maintenance of the software. Smoothing workflow through process automation and monitoring system performance ensures reliable operation, easily scalable with the growth of your project.",
        img: DE,
      },
      {
        title: "UI/UX Designers",
        description: "The UI/UX Designers create intuitive and user-friendly interfaces that enhance customer experience. They can develop anything from wireframes to final designs, putting a focus on usability to make it engaging and very easy to use. They keep the end-user in mind at each design step.",
        img: UI,
      },
      {
        title: "QA Engineers",
        description: "QA Engineers set high standards by rigorous testing of your software in every possible stage of development. This ensures that defects are found early and resolved so that by the time the final product is out, it is robust, reliable, bug-free, and seamless for any user.",
        img: QA,
      },
      {
        title: "Business Analysts",
        description: "If you hire a full-scale dedicated software development team at OS-System, Our Business Analysts align project goals with your business objectives by gathering requirements, analyzing workflows, and recommending solutions. This maintains focus through the development process on delivering measurable value that addresses strategic needs.",
        img: BA,
      },
    ]
  },
  choose: {
    title: 'Why you should choose us',
    description: 'Choosing our OS-System dedicated team means allying with experts committed to delivering quality software solutions.',
    cases: [
      {
        number: "01",
        title: "Proven Experience and Specialized Skills",
        description: "If speed is critical, our dedicated software developers speed up development by providing a preassembled group ready to get down to business straight away. Because they have been working on similar projects, they know how to do it efficiently and thus get your product on the market faster. This gives you an edge over the competition.",
      },
      {
        number: "02",
        title: "Transparency in Communication and Collaboration",
        description: "With OS-System, you are always deep in. We believe in clear, open communication and will keep you abreast of our progress, our challenges, and our successes in order to ensure that the collaboration is on target to meet your goals and is responsive to your feedback.",
      },
      {
        number: "03",
        title: "Flexible and Scalable Team Structure",
        description: "Our dedicated team model allows for flexibility in scaling up or down resources according to the changes in project needs. This adaptability ensures that the size and skill sets of your team are appropriate at each particular stage to optimize efficiency and cost-effectiveness.",
      },
      {
        number: "04",
        title: "Committed to High-Quality Deliverables",
        description: "Quality is at the heart of all we do. From extensive testing to perfectionist development practices, our experts develop robust and reliable software with high performance, ensuring security and the highest degree of user satisfaction.",
      },
      {
        number: "05",
        title: "Cost-Effective Solutions for Maximum Value",
        description: "Our dedicated development team services are cost-effective compared to in-house development. You tap into skilled specialists with no overhead of hiring and training to make the most of your investment.",
      },
      {
        number: "06",
        title: "Commitment to Long-Term Partnership",
        description: "We don't just deliver projects. We forge relationships. With OS-System, the high level of commitment to growing with you and supporting your continued success during and after any project's completion is what you will find.",
      },
    ]
  },
  keys: {
    title: 'Easy steps to hire our dedicated team',
    description: '',
    cases: [
      {
        title: "Define Project Requirements",
        description: "If you hire dedicated software development team at OS-System, we start by understanding your project goals, technical requirements, and team needs. This helps us identify the right skills and resources that best match your vision and objectives.",
        number: "01",
        img: Define,
      },
      {
        title: "Select Team Members",
        description: "We will suggest qualified experts from our talent pool to meet your needs. You can go through their experience and expertise to ensure that they are the best fit for your project.",
        number: "02",
        img: Select,
      },
      {
        title: "Introductory Meeting",
        description: "Get to know your future team members during the introductory call, during which you discuss the project details, timeline, and collaboration preferences. Make sure both sides are aligned with goals and expectations.",
        number: "03",
        img: Introductory,
      },
      {
        title: "Finalize Contract Terms",
        description: "Once happy with the team, we move on to contract terms that specify scope, timelines, and budget, giving full insight into how we will work with each other.",
        number: "04",
        img: Finalize,
      },
      {
        title: "Onboarding of the Team",
        description: "The onboarding process introduces the team to project tools, workflows, and communication channels so that they can take off like a shot and hit the ground running.",
        number: "05",
        img: Onboarding,
      },
      {
        title: "Start of Project Development",
        description: "With all this in place, our dedicated team will start working on your project and keep you updated on its progress. They follow your milestones closely to make sure the process flow is smooth, and that quality is delivered.",
        number: "06",
        img: Start,
      },
    ]
  },
}

export default dataForDedicatedTeam
